<template>
  <!--xxxxxxxxxxxx footer xxxxxxxxxxxx-->
    <footer id="footer" class="footer">
        <div class="container">
            <div class="d-grid footer__wrapper">
                <div class="footer__content">
                    <!-- <h4 class="footer__title">Email Me</h4> -->
                    <div class="contact_footer">
                        <span class="ri-phone-line"></span>
                        <a href="#" class="footer__contact">(+351) 910 559 307</a>
                    </div>
                    <div>
                        <span class="ri-mail-line"></span>
                        <a href="#" class="footer__contact">jorgemopanc@icloud.com</a>
                    </div>
                </div>
                <div class="footer__content social_links">
                    <h4 class="footer__title">Follow Me</h4>
                    <ul class="footer__social-list">
                        <li class="footer__social-item">
                            <a href="https://www.facebook.com/jorgemopanc" class="footer__social-link">
                                <i class="ri-facebook-fill"></i>
                            </a>
                        </li>
                        <li class="footer__social-item">
                            <a href="https://www.instagram.com/jorgemopanc/" class="footer__social-link">
                                <i class="ri-instagram-fill"></i>
                            </a>
                        </li>
                        <li class="footer__social-item">
                            <a href="https://twitter.com/JorgeMo56542670" class="footer__social-link">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="footer__social-item">
                            <a href="https://github.com/mopanc" class="footer__social-link">
                                <i class="ri-github-fill"></i>
                            </a>
                        </li>
                        <li class="footer__social-item">
                            <a href="https://www.linkedin.com/in/jorge-mopanc/" class="footer__social-link">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                        <li class="footer__social-item">
                            <a href="https://codepen.io/mopanc" class="footer__social-link">
                                <i class="ri-codepen-line"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <p class="footer__copyright">&copy; 2023 Jorge Morais. All Rights Reserved</p>
        </div>
    </footer>
</template>

<script>

</script>

<style scoped>
.ri-phone-line {
    color: #fff;
    margin-right: 10px;
    vertical-align: middle;
    text-decoration-line: none;
}

.ri-mail-line {
    color: #fff;
    margin-right: 10px;
    vertical-align: middle;
    text-decoration-line: none;
}

.contact_footer {
    margin: 10px 0;
}

.footer__copyright {
    color: #fff;
}
</style>