<template>
<main class="main">
        <section id="contact" class="section contact">
            <div class="container">
              <div class="section__header">
                <h2 class="section__title"></h2>
                <span class="section__subtitle"></span>
              </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/48-coursera.png" alt="Sql">
                        <div>
                          <h2 class="contact__title-cert">Certificate Sql</h2>
                          <p class="contact__description-cert">Coursera - IBM <br/> A Practical Introduction for Querying Databases - Finished with Honors </p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/1-cursoemvideohtml5.png" alt="html5">
                        <div>
                          <h2 class="contact__title-cert certificate_html"></h2>
                          <p class="contact__description-cert certificate_html_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/2-dowhile.png" alt="DoWhile">
                        <div>
                          <h2 class="contact__title-cert certificate_dowhile"></h2>
                          <p class="contact__description-cert certificate_dowhile_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/3-arquiteturasistemasavancada.png" alt="Advanced systems architecture">
                        <div>
                          <h2 class="contact__title-cert certificate_arq_advanced"></h2>
                          <p class="contact__description-cert certificate_arq_advanced_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/4-androidstudio.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_android_studio"></h2>
                          <p class="contact__description-cert certificate_android_studio_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/5-estruturadedadosalgoritmos.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_data_structures"></h2>
                          <p class="contact__description-cert certificate_data_structures_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/6-fundamentosarquiteturasistemas.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_arq_fund"></h2>
                          <p class="contact__description-cert certificate_arq_fund_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/7-debuggingcatcherrorsexeptions.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_debug"></h2>
                          <p class="contact__description-cert certificate_debug_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/8-es6essencial.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_ecmascript_essential"></h2>
                          <p class="contact__description-cert certificate_ecmascript_essential_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/9-es6avancado.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_ecmascript_advanced"></h2>
                          <p class="contact__description-cert certificate_ecmascript_advanced_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/10-introducaojavascript.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_ecmascript_introduction"></h2>
                          <p class="contact__description-cert certificate_ecmascript_introduction_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/12-introducaoreactjs.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_reactjs"></h2>
                          <p class="contact__description-cert certificate_reactjs_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/13-intrreact.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_reactjs_intro"></h2>
                          <p class="contact__description-cert certificate_reactjs_description_intro"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/14-intrvuejs.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_vuejs_intro"></h2>
                          <p class="contact__description-cert certificate_vuejs_description_intro"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/15-intrjquery.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_jquery_intro"></h2>
                          <p class="contact__description-cert certificate_jquery_description_intro"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/16-javascript.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_web_js"></h2>
                          <p class="contact__description-cert certificate_web_js_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/17-logicadeprogramacao.png" alt="Android Studio">
                        <div>
                          <h2 class="contact__title-cert certificate_logic"></h2>
                          <p class="contact__description-cert certificate_logic_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/18-logicaprogramacaoessencial.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_logic_essential"></h2>
                            <p class="contact__description-cert certificate_logic_essential_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/19-primeirospassospregramacao.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_first_steps"></h2>
                            <p class="contact__description-cert certificate_first_steps_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/20-intrjavascript.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_javascript_introduction"></h2>
                            <p class="contact__description-cert certificate_javascript_introduction_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/21-intrgitgithub.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_github_introduction"></h2>
                            <p class="contact__description-cert certificate_github_introduction_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/22-htmlwebdeveloper.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_html_web"></h2>
                            <p class="contact__description-cert certificate_html_web_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/23-asyncawait.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_async"></h2>
                            <p class="contact__description-cert certificate_async_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/24-becameremote.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_remote"></h2>
                            <p class="contact__description-cert certificate_remote_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/25-bootcampsantander.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_santander"></h2>
                            <p class="contact__description-cert certificate_santander_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/26-colectionsarrayslistas.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_arrays"></h2>
                            <p class="contact__description-cert certificate_arrays_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/27-componentesmetodoslifecyclesvuejs.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_cycles_vue"></h2>
                            <p class="contact__description-cert certificate_cycles_vue_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/28-conceitosappsandroid.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_apps_android"></h2>
                            <p class="contact__description-cert certificate_apps_android_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/29-conseitosUX.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_ux"></h2>
                            <p class="contact__description-cert certificate_ux_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/30-fetchAPI.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_fetch"></h2>
                            <p class="contact__description-cert certificate_fetch_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/31-git.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_git"></h2>
                            <p class="contact__description-cert certificate_git_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/32-htmlecss.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_htmlcss"></h2>
                            <p class="contact__description-cert certificate_htmlcss_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
                <div class="d-grid contact__wrapper-cert">
                    <div class="contact__content-cert">
                        <img class="contact__img-cert" src="../assets/img/certificates/33-intrkotlin.png" alt="Android Studio">
                        <div>
                            <h2 class="contact__title-cert certificate_kotlin"></h2>
                            <p class="contact__description-cert certificate_kotlin_description"></p>
                        </div>
                    </div>
                    <div class="contact__btn">
                        <a class="btn btn--secondary button--modal"></a>
                    </div>
                </div>
            </div>
            <!-- Modal -->
            <div id="certificate-modal" class="modal">
              <div class="modal-content">
                <span class="close">&times;</span>
                <img id="modal-certificate-img" src="" alt="Certificate">
              </div>
            </div>
        </section>
    </main>
</template>
<script setup>
// eslint-disable-next-line no-unused-vars
import LanguageSelector from "../components/LanguageSelector.vue"
import { ref, onMounted } from "vue"


document.addEventListener("DOMContentLoaded", function() {
  // Obtém referências para os elementos do modal
  const modal = document.getElementById("certificate-modal");
  const modalImage = document.getElementById("modal-certificate-img");
  const body = document.querySelector("body");

  // Obtém referências para os botões "See"
  const seeButtons = document.querySelectorAll(".button--modal");

  // Adiciona um evento de clique a cada botão "See"
  seeButtons.forEach(function(button) {
    button.addEventListener("click", function() {
      // Obtém os detalhes do certificado correspondente ao botão clicado
      const certificateImage = this.parentNode.previousElementSibling.querySelector(".contact__img-cert").src;
      modalImage.src = certificateImage;
      modal.style.display = "block";
    });
  });

  // Adiciona um evento de clique para fechar o modal quando o botão "x" é clicado
  const closeButton = document.getElementsByClassName("close")[0];
  closeButton.addEventListener("click", function() {
    modal.style.display = "none";
  });

  // Adiciona um evento de clique ao elemento raiz
  body.addEventListener("click", function(event) {
    // Verifica se o clique ocorreu fora do modal
    if (event.target === modal) {
      modal.style.display = "none";
    }
  });
});

const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en')
console.log(selectedLanguage.value)

onMounted(() => {
  import(`../languages/${selectedLanguage.value}.js`).then((module) => {
    const expressions = module.default;
     document.querySelector('.section__title').textContent = expressions.section_title;
     document.querySelector('.section__subtitle').textContent = expressions.section_subtitle;
     document.querySelector('.certificate_html').textContent = expressions.certificate_html;
     document.querySelector('.certificate_html_description').textContent = expressions.certificate_html_description;
     document.querySelector('.certificate_dowhile').textContent = expressions.certificate_dowhile;
     document.querySelector('.certificate_dowhile_description').textContent = expressions.certificate_dowhile_description;
     document.querySelector('.certificate_arq_advanced').textContent = expressions.certificate_arq_advanced;
     document.querySelector('.certificate_arq_advanced_description').textContent = expressions.certificate_arq_advanced_description;
     document.querySelector('.certificate_android_studio').textContent = expressions.certificate_android_studio;
     document.querySelector('.certificate_android_studio_description').textContent = expressions.certificate_android_studio_description;
     document.querySelector('.certificate_data_structures').textContent = expressions.certificate_data_structures;
     document.querySelector('.certificate_data_structures_description').textContent = expressions.certificate_data_structures_description;
     document.querySelector('.certificate_arq_fund').textContent = expressions.certificate_arq_fund;
     document.querySelector('.certificate_arq_fund_description').textContent = expressions.certificate_arq_fund_description;
     document.querySelector('.certificate_debug').textContent = expressions.certificate_debug;
     document.querySelector('.certificate_debug_description').textContent = expressions.certificate_debug_description;
     document.querySelector('.certificate_ecmascript_essential').textContent = expressions.certificate_ecmascript_essential;
     document.querySelector('.certificate_ecmascript_essential_description').textContent = expressions.certificate_ecmascript_essential_description;
     document.querySelector('.certificate_ecmascript_advanced').textContent = expressions.certificate_ecmascript_advanced;
     document.querySelector('.certificate_ecmascript_advanced_description').textContent = expressions.certificate_ecmascript_advanced_description;
     document.querySelector('.certificate_ecmascript_introduction').textContent = expressions.certificate_ecmascript_introduction;
     document.querySelector('.certificate_ecmascript_introduction_description').textContent = expressions.certificate_ecmascript_introduction_description;
     document.querySelector('.certificate_reactjs').textContent = expressions.certificate_reactjs;
     document.querySelector('.certificate_reactjs_description').textContent = expressions.certificate_reactjs_description;
     document.querySelector('.certificate_reactjs_intro').textContent = expressions.certificate_reactjs_intro;
     document.querySelector('.certificate_reactjs_description_intro').textContent = expressions.certificate_reactjs_description_intro;
     document.querySelector('.certificate_vuejs_intro').textContent = expressions.certificate_vuejs_intro;
     document.querySelector('.certificate_vuejs_description_intro').textContent = expressions.certificate_vuejs_description_intro;
     document.querySelector('.certificate_jquery_intro').textContent = expressions.certificate_jquery_intro;
     document.querySelector('.certificate_jquery_description_intro').textContent = expressions.certificate_jquery_description_intro;
     document.querySelector('.certificate_web_js').textContent = expressions.certificate_web_js;
     document.querySelector('.certificate_web_js_description').textContent = expressions.certificate_web_js_description;
     document.querySelector('.certificate_logic').textContent = expressions.certificate_logic;
     document.querySelector('.certificate_logic_description').textContent = expressions.certificate_logic_description;
     document.querySelector('.certificate_logic_essential').textContent = expressions.certificate_logic_essential;
     document.querySelector('.certificate_logic_essential_description').textContent = expressions.certificate_logic_essential_description;
     document.querySelector('.certificate_first_steps').textContent = expressions.certificate_first_steps;
     document.querySelector('.certificate_first_steps_description').textContent = expressions.certificate_first_steps_description;
     document.querySelector('.certificate_javascript_introduction').textContent = expressions.certificate_javascript_introduction;
     document.querySelector('.certificate_javascript_introduction_description').textContent = expressions.certificate_javascript_introduction_description;
     document.querySelector('.certificate_github_introduction').textContent = expressions.certificate_github_introduction;
     document.querySelector('.certificate_github_introduction_description').textContent = expressions.certificate_github_introduction_description;
     document.querySelector('.certificate_html_web').textContent = expressions.certificate_html_web;
     document.querySelector('.certificate_html_web_description').textContent = expressions.certificate_html_web_description;
     document.querySelector('.certificate_async').textContent = expressions.certificate_async;
     document.querySelector('.certificate_async_description').textContent = expressions.certificate_async_description;
     document.querySelector('.certificate_remote').textContent = expressions.certificate_remote;
     document.querySelector('.certificate_remote_description').textContent = expressions.certificate_remote_description;
     document.querySelector('.certificate_santander').textContent = expressions.certificate_santander;
     document.querySelector('.certificate_santander_description').textContent = expressions.certificate_santander_description;
     document.querySelector('.certificate_arrays').textContent = expressions.certificate_arrays;
     document.querySelector('.certificate_arrays_description').textContent = expressions.certificate_arrays_description;
     document.querySelector('.certificate_cycles_vue').textContent = expressions.certificate_cycles_vue;
     document.querySelector('.certificate_cycles_vue_description').textContent = expressions.certificate_cycles_vue_description;
     document.querySelector('.certificate_apps_android').textContent = expressions.certificate_apps_android;
     document.querySelector('.certificate_apps_android_description').textContent = expressions.certificate_apps_android_description;
     document.querySelector('.certificate_ux').textContent = expressions.certificate_ux;
     document.querySelector('.certificate_ux_description').textContent = expressions.certificate_ux_description;
     document.querySelector('.certificate_fetch').textContent = expressions.certificate_fetch;
     document.querySelector('.certificate_fetch_description').textContent = expressions.certificate_fetch_description;
     document.querySelector('.certificate_git').textContent = expressions.certificate_git;
     document.querySelector('.certificate_git_description').textContent = expressions.certificate_git_description;
     document.querySelector('.certificate_htmlcss').textContent = expressions.certificate_htmlcss;
     document.querySelector('.certificate_htmlcss_description').textContent = expressions.certificate_htmlcss_description;
     document.querySelector('.certificate_kotlin').textContent = expressions.certificate_kotlin;
     document.querySelector('.certificate_kotlin_description').textContent = expressions.certificate_kotlin_description;


    // Obtém todos os botões "See"
    const seeButtons = document.querySelectorAll('.button--modal');

    // Itera sobre cada botão "See"
    seeButtons.forEach((button) => {
      button.textContent = expressions.see;
    });
    //  document.querySelector('#certificates').textContent = expressions.certificates;
    //  document.querySelector('#contact').textContent = expressions.contact;
  });
});

</script>

<style>

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.button--modal {
  cursor: pointer;
}

.modal-content {
  background-color: #fefefe;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 900px;
  max-height: 580px;
}

#modal-certificate-img {
  max-width: 900px;
  max-height: 540px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.section__title {
  color: #fff;
}

@media screen and (max-width: 764px) {
    .btn--secondary {
        width: 100%;
        margin-top: 0 !important;
    }
    .contact__content-cert {
        display: block;
    }
    .contact__img-cert {
        min-width: 100%;
        max-height: 200px;
        margin-bottom: 4rem;
    }
}
</style>